// src/pages/HomePage.js
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AboutUs from '../components/AboutUs';
import Services from '../components/Services';
import Testimonials from '../components/Testimonials';
import home from '../assets/images/Background_For_Home.png'; 
import Features from '../components/Features'; 
import MapComponent from '../components/MapComponent'; 
import Footer from '../components/Footer';

const HomeContainer = styled.div`
  text-align: center;
  font-family: 'Lato', sans-serif;
  overflow-x: hidden;
  padding: 0 10px;
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
`;

const Banner = styled.div`
  background-color: #003366;
  color: white;
  background-image: url(${home});
  background-size: cover;
  background-position: top; 
  background-repeat: no-repeat;
  padding: 20px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;

  @media (max-width: 768px) {
    background-size: cover;
    background-position: top;
    padding: 15px;
    min-height: 300px;
  }

  @media (max-width: 480px) {
    background-size: cover;
    background-position: top center;
    padding: 10px;
    min-height: 250px;
  }
`;

const BannerTitle = styled.h1`
  font-family: 'Lato', sans-serif;
  color: #FFFFFF;
  text-shadow: 1px 1px 25% rgba(0, 0, 0, 0.25);
  font-size: 2rem;
  max-width: 100%;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 1.25rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const StyledParagraph = styled.div`
  font-family: 'Lato', sans-serif;
  color: #FFFCF6;
  text-shadow: 1px 1px 25% rgba(0, 0, 0, 0.25);
  line-height: 1.2;
  font-weight: 700;
  font-size: 4.2rem;
  max-width: 100%;
  margin: 10px auto;
  padding: 0;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const StyledButton = styled.button`
  background-color: #FFFCF6;
  color: #02155A;
  font-weight: bold;
  font-size: 0.875rem;
  width: 140px;
  height: 35px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  padding: 5px 10px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #02155A;
    color: #FFFCF6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 768px) {
    font-size: 0.75rem;
    width: 120px;
    height: 30px;
  }

  @media (max-width: 480px) {
    font-size: 0.65rem;
    width: 100px;
    height: 25px;
  }
`;

const Line = styled.p`
  margin: 0;
`;

const HomePage = forwardRef((props, ref) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/contact');
  };

  return (
    <HomeContainer>
      <Banner>
        <BannerTitle>Unlock Your Global Potential</BannerTitle>
        <StyledParagraph>
          <Line>Your Journey to Study Abroad</Line>
          <Line>Starts Here!</Line>
        </StyledParagraph>
        <StyledButton onClick={handleButtonClick}>Let's Talk</StyledButton>
      </Banner>
      <Features />
      <AboutUs />
      <Services />
      <MapComponent />
      <Testimonials />
      <Footer ref={ref} />
    </HomeContainer>
  );
});

export default HomePage;
