// src/pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { supabase } from '../supabase'; // Import Supabase client

const DashboardContainer = styled.div`
  padding: 20px;
  max-width: 1130px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  padding: 12px;
  background-color: #003366;
  color: white;
  border: 1px solid #ccc;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  padding: 12px;
  border: 1px solid #ccc;
`;

const MessageCell = styled.td`
  padding: 12px;
  border: 1px solid #ccc;
  max-width: 150px;
  white-space: pre-wrap; /* This allows the text to wrap */
  word-wrap: break-word; /* This ensures long words break properly */
`;

const Button = styled.button`
  padding: 6px 12px;
  background-color: #d9534f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #c9302c;
  }
`;


// Function to split a message into chunks of a specified number of words
const splitMessage = (message, wordsPerChunk = 30) => {
  const words = message.split(' ');
  const chunks = [];
  for (let i = 0; i < words.length; i += wordsPerChunk) {
    chunks.push(words.slice(i, i + wordsPerChunk).join(' '));
  }
  return chunks.join('\n');
};

const Dashboard = () => {
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        let { data, error } = await supabase
          .from('contact_forms') // Use your table name
          .select('*')
          .order('id', { ascending: false }); // Order by id in descending order

        if (error) {
          console.error('Error fetching data:', error);
          return;
        }

        setSubmissions(data);
      } catch (error) {
        console.error('Unexpected error:', error);
      }
    };

    fetchSubmissions();
  }, []);

  const handleDelete = async (id) => {
    try {
      let { error } = await supabase
        .from('contact_forms') // Use your table name
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Error deleting data:', error);
        return;
      }

      // Update the state to remove the deleted item
      setSubmissions(submissions.filter(submission => submission.id !== id));
    } catch (error) {
      console.error('Unexpected error:', error);
    }
  };

  return (
    <DashboardContainer>
      <h2>Contact Form Dashboard</h2>
      <Table>
        <thead>
          <tr>
            <TableHeader>Name</TableHeader>
            <TableHeader>Email</TableHeader>
            <TableHeader>Contact</TableHeader>
            <TableHeader>Destination</TableHeader>
            <TableHeader>Qualification</TableHeader>
            <TableHeader>Intake</TableHeader>
            <TableHeader>Message</TableHeader>
            <TableHeader>Submitted At</TableHeader>
            <TableHeader>Actions</TableHeader>
          </tr>
        </thead>
        <tbody>
          {submissions.map((submission, index) => (
            <TableRow key={index}>
              <TableCell>{submission.name}</TableCell>
              <TableCell>{submission.email}</TableCell>
              <TableCell>{submission.contact}</TableCell>
              <TableCell>{submission.destination}</TableCell>
              <TableCell>{submission.qualification}</TableCell>
              <TableCell>{submission.intake}</TableCell>
              <MessageCell>{splitMessage(submission.message)}</MessageCell>
              <TableCell>{new Date(submission.submitted_at).toLocaleString()}</TableCell>
              <TableCell>
                <Button onClick={() => handleDelete(submission.id)}>Delete</Button>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </DashboardContainer>
  );
};

export default Dashboard;
