import React from 'react';
import styled from 'styled-components';
import serviceCardImage from '../assets/images/Service_Card.png'; // Adjust the path as needed

const ServicesContainer = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #FFFCF6;  
`;

const ServicesTitle = styled.h2`
  color: #1e3a8a;
  font-size: 24px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 20px; /* Adjust title size for smaller screens */    
  }
`;

const ServicesWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  flex-wrap: wrap; /* Ensure cards wrap on smaller screens */
  padding: 0 ; /* Add padding to avoid edge collisions on smaller screens */
  margin-left: -20px; /* Shift the entire group of cards slightly to the left */

   @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns on mobile screens */
    gap: 10px; /* Reduce gap for smaller screens */
    margin-left: -30px; /* Shift the entire group of cards slightly to the left */

    
  }
`;

const ServiceCard = styled.div`
  position: relative;
  width: 206px;
  height: 228px; /* Adjust height as needed */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 20px; /* Space between rows */

 @media (max-width: 768px) {
    width: 100%; /* Full width on smaller screens */
    max-width: 300px; /* Limit the maximum width */
    margin-left: auto;
    margin-right: auto;
  }
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const ServiceHeader = styled.div`
  color: #ffffff;
  font-weight: bold;
  padding: 30px;
  text-align: center;
  font-size: 16px;
  position: absolute;
  width: 70%;

  @media (max-width: 768px) {
    font-size: 14px; /* Adjust font size for smaller screens */
    padding: 30px; 
    text-align: center;
    width: 60%; 
  }
`;


const ServiceBody = styled.div`
  font-size: 14px;
  color: #003366;
  text-align: center;
  line-height: 1.5;
  position: absolute;
  top: 60px; 
  width: 100%;
  padding: 50px 0px;

 @media (max-width: 768px) {
    font-size: 12px; 
    padding: 50px 0px;
    top: 60px; 
    width: 100%;
  }
`;

const ServiceCard1 = styled.div`
  padding: 15px;
  margin: 20px;

  @media (max-width: 768px) {
    width: calc(100% - 20px); /* Two cards per row on mobile */
    margin: 10px;
  }
`;

const ServiceCard2 = styled.div`
  padding: 15px;

  @media (max-width: 768px) {
    width: calc(100% - 20px); /* Two cards per row on mobile */
    margin: 10px;
  }
`;

const ServiceCard3 = styled.div`
  padding: 15px;
  margin: 20px;

  @media (max-width: 768px) {
    width: calc(100% - 20px); /* Two cards per row on mobile */
    margin: 10px;
  }
`;

const ServiceCard4 = styled.div`
  padding: 15px;

  @media (max-width: 768px) {
    width: calc(100% - 20px); /* Two cards per row on mobile */
    margin: 10px;
  }
`;


const Services = () => {
  return (
    <ServicesContainer>
      <ServicesTitle>Our services tailor-made for every student</ServicesTitle>
      <ServicesWrapper>
        <ServiceCard1>
          <ServiceCard>
            <ServiceImage src={serviceCardImage} alt="Service 1" />
            <ServiceHeader>01.</ServiceHeader>
            <ServiceBody>
              Course Selection,country selection & Complete Visa Application Process Guidance
            </ServiceBody>
          </ServiceCard>
        </ServiceCard1>

        <ServiceCard2>
          <ServiceCard>
            <ServiceImage src={serviceCardImage} alt="Service 2" />
            <ServiceHeader>02.</ServiceHeader>
            <ServiceBody>
              GRE/ IELTS/ TOEFL Guidance
            </ServiceBody>
          </ServiceCard>
        </ServiceCard2>

        <ServiceCard3>
          <ServiceCard>
            <ServiceImage src={serviceCardImage} alt="Service 3" />
            <ServiceHeader>03.</ServiceHeader>
            <ServiceBody>
              Scholarships and Financial Guidance
            </ServiceBody>
          </ServiceCard>
        </ServiceCard3>

        <ServiceCard4>
          <ServiceCard>
            <ServiceImage src={serviceCardImage} alt="Service 4" />
            <ServiceHeader>04.</ServiceHeader>
            <ServiceBody>
              SOP/ LOR Guidance & End-to-End Student Support
            </ServiceBody>
          </ServiceCard>
        </ServiceCard4>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
