import React from 'react';
import styled from 'styled-components';
import ContactForm from '../components/ContactForm';// Adjust the import path as needed
import FooterImage from '../assets/images/Footer-images.png'; // Adjust the import path as needed

const PageContainer = styled.div`
  width: 98%;
  max-width: 1200px; /* Set a maximum width for larger screens */
  margin: 0 auto; /* Center the container horizontally *
  padding: 10px;
  box-sizing: border-box; 
`;

const Header = styled.h1`
  color: #02155A; 
  font-size: 2em; /* Adjust font size for larger screens */
  text-align: left;
  margin-bottom: 10px; 

  @media (max-width: 768px) {
    font-size: 1.5em; /* Smaller font size on smaller screens */
    margin-bottom: 10px; /* Add margin for spacing */
  }
`;

const Subtitle = styled.p`
  text-align: left;
  margin-bottom: 20px; /* Add margin for spacing */
  color: #212121; 
`;

const ImageSection = styled.div`
  margin-top: 5px; /* Add margin below the image section */
`;

const Image = styled.img`
  width: 100%;
  border-radius: 8px; /* Add rounded corners to the image */
`;

const FormContainer = styled.div`
  background-color: #EFEFEF; 
  padding: 20px; 
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
`;

const ContactPage = () => {
  return (
    <PageContainer>
      <ImageSection>
        <Image src={FooterImage} alt="Footer" />
      </ImageSection>
      <FormContainer>
        <Header>Send A Message</Header>
        <Subtitle>How can we help you with achieving your dream for study abroad</Subtitle>
        <ContactForm />
      </FormContainer>
    </PageContainer>
  );
};

export default ContactPage;