// src/components/Footer.js
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import FooterImage from '../assets/images/Footer-images.png';
import EmailIcon from '../assets/images/Email-icon.png';
import PhoneIcon from '../assets/images/Call-icon.png';
import MapIcon from '../assets/images/Map-icon.png';

const FooterContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #02155A;
  color: #fff;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const FooterContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const FooterTitle = styled.h2`
  font-family: 'Lato', sans-serif;
  font-size: 30px;
  line-height: 30px;
  color: #FFFFFF;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (max-width: 768px) {
    margin: 20px 0;
    font-size: 24px;
  }
`;

const FooterDetail = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  white-space: pre-line;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
  }
`;

const FooterIcon = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 15px;
  flex-shrink: 0;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
    width: 25px;
    height: 25px;
  }
`;

const FooterText = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
`;

const FooterTextAddress = styled.p`
  font-family: 'Lato', sans-serif;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin: 0;
  margin-right: 54px;
  white-space: pre-line;
  
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    margin-right: 0;
    margin-top: 5px;
    text-align: center;
  }
`;

const FooterImageWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    justify-content: center;
    img {
      width: 100%;
    }
  }
`;

const Footer = forwardRef((props, ref) => {
  return (
    <FooterContainer ref={ref}>
      <FooterContent>
        <FooterTitle>Contact Us</FooterTitle>
        <FooterDetail>
          <FooterIcon src={EmailIcon} alt="Email Icon" />
          <FooterText>Email: utkarsh.edutipscholars@gmail.com</FooterText>
        </FooterDetail>
        <FooterDetail>
          <FooterIcon src={PhoneIcon} alt="Phone Icon" />
          <FooterText>Phone: 95457 34140 | 86690 16190</FooterText>
        </FooterDetail>
        <FooterDetail>
          <FooterIcon src={MapIcon} alt="Map Icon" />
          <FooterTextAddress>
            Address: A5/10 Meeranagar Terrace Society
            Lane-7 Koregaon Park Pune Maharashtra
          </FooterTextAddress>
        </FooterDetail>
      </FooterContent>
      <FooterImageWrapper>
        <img src={FooterImage} alt="Footer Background" />
      </FooterImageWrapper>
    </FooterContainer>
  );
});

export default Footer;
