import React from 'react';
import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../assets/images/Logo_EduTip.png'; // Adjust the path based on your file structure

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #FFFCF6;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  max-width: 1200px; 
  margin: 0 auto; 
  width: 100%; 
  box-sizing: border-box; 
  flex-wrap: wrap;

  @media (max-width: 480px) {
    padding: 10px 15px; /* Adjust padding for mobile */
  }
`;

const Logo = styled.img`
  width: 150px;
  height: auto; 
  
  @media (max-width: 768px) {
    width: 120px;
  }

  @media (max-width: 480px) {
    width: 100px;
  }
`;

const Nav = styled.nav`
  display: flex;
  gap: 40px;

  @media (max-width: 768px) {
    gap: 20px;
  }

  @media (max-width: 480px) {
    gap: 2px;
    justify-content: flex-end; /* Align to the right on mobile */
    width: auto; /* Allow the nav to shrink */
    flex-wrap: wrap; /* Wrap items if needed */
  }
`;

const NavLink = styled(Link)`
  color: #02155A;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    color: #02155A;
    text-decoration: underline;
  }

  @media (max-width: 480px) {
    padding: 5px 10px; /* Adjust padding for smaller screens */
  }
`;

const LogoutButton = styled.button`
  background-color: #02155a;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;

  &:hover {
    background-color: #01326d;
  }

  @media (max-width: 480px) {
    padding: 8px 15px; /* Adjust button padding for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }
`;

const Header = ({ isLoggedIn, onLogout, onContactClick }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/home'); // Redirect to the home page
  };

  return (
    <HeaderContainer>
      <Logo src={logo} alt="Edu Tip Logo" />
      <Nav>
        {!isLoggedIn && <NavLink to="/home" aria-label="Home">Home</NavLink>}
        <NavLink to="#" onClick={onContactClick} aria-label="Contact">Contact</NavLink>
        {!isLoggedIn ? (
          <NavLink to="/login" aria-label="Login">Login</NavLink>
        ) : (
          <LogoutButton onClick={handleLogout} aria-label="Logout">Logout</LogoutButton>
        )}
      </Nav>
    </HeaderContainer>
  );
};

export default Header;
