import React from 'react';
import styled from 'styled-components';
import globeImage from '../assets/images/Globe_Background.png'; // Replace with the path to your image

const AboutUsContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
    padding: 15px;
  }
`;

const AboutUsImage = styled.img`
  width: 50%;
  max-width: 500px;
  border-radius: 8px;
  margin-right: 20px;

  @media (max-width: 768px) {
    margin: 0 0 20px 0;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`;

const AboutUsContent = styled.div`
  flex: 1;
  max-width: 600px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const AboutUsTitle = styled.h2`
  color: #1e3a8a;
  margin-bottom: 10px;
  margin-left: 20px;
  text-align: left;

  @media (max-width: 768px) {
    margin-left: 0;
    text-align: center;
  }
`;

const AboutUsText = styled.p`
  font-size: 1rem;
  line-height: 1.6;
  margin-left: 20px;
  color: #333; 
  text-align: left;
  

  @media (max-width: 768px) {
    margin-left: 0;
    text-align: left;
  }
`;

const AboutUs = () => {
  return (
    <AboutUsContainer>
      <AboutUsImage src={globeImage} alt="Globe with graduation cap" />
      <AboutUsContent>
        <AboutUsTitle>About Us</AboutUsTitle>
        <AboutUsText>
          At Edu Tip Scholars Abroad Consultation, we are dedicated to helping students achieve their dreams of studying abroad. With expert guidance and personalized support, we simplify the complex process of international education. From selecting the right university to visa assistance and pre-departure orientation, our experienced team ensures a smooth transition. Trust Edu Tip Scholars to provide comprehensive advice and tailor-made solutions, empowering you to excel in your academic journey across the globe.
        </AboutUsText>
      </AboutUsContent>
    </AboutUsContainer>
  );
};

export default AboutUs;
