import React from 'react';
import styled from 'styled-components';
import Client1Image from '../assets/images/Client-1.png';
import Client2Image from '../assets/images/Client-2.png';

const TestimonialsContainer = styled.div`
  text-align: center;
  padding: 40px 20px;  /* Increased padding for better spacing */
  background-color: #FFFCF6;
`;

const StyledH2 = styled.h2`
  color: #02155A;
  font-size: 2em;
  margin-bottom: 40px;  /* Increased bottom margin for better spacing */
`;

const TestimonialWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TestimonialCard = styled.div`
  background-color: #02155A;
  color: #fff;
  padding: 20px;
  border-radius: 10px;
  max-width: 300px;
  text-align: center;
  margin: 10px;

  @media (max-width: 768px) {
    max-width: 90%;  /* Ensure the cards don't take up full width on small screens */
  }
`;

const ClientImage = styled.img`
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-bottom: 15px;  /* Added margin for better spacing */
`;

const TestimonialText = styled.p`
  margin: 20px 0;
  text-align: left;
  font-size: 1rem;
  line-height: 1.6;  /* Improved line height for readability */
`;

const testimonials = [
  {
    id: 1,
    text: "I would like to mention that EduTip team of counsellors and Loan experts played a pivotal role in guiding me and turning my dream of studying abroad into reality. After applying to the four top-ranked Australian Universities I was surprised to get an admit letter from all 4! Thank you Edu Tip Scholars. I highly recommend you to go with them.",
    image: Client1Image,
    name: 'Client 1',
  },
  {
    id: 2,
    text: "The team helped me completely from course selection to visa application and even IELTS preparation. All of this was made possible completely remotely and I was in the UK within 3 months of first contacting them. Highly responsive team. It was truly a one stop solution for all my needs. Thank you !!",
    image: Client2Image,
    name: 'Client 2',
  },
];

const Testimonials = () => {
  return (
    <TestimonialsContainer>
      <StyledH2>Client Testimonials</StyledH2>
      <TestimonialWrapper>
        {testimonials.map((testimonial) => (
          <TestimonialCard key={testimonial.id}>
            <ClientImage src={testimonial.image} alt={`Photo of ${testimonial.name}`} />
            <TestimonialText>{testimonial.text}</TestimonialText>
          </TestimonialCard>
        ))}
      </TestimonialWrapper>
    </TestimonialsContainer>
  );
};

export default Testimonials;
