// src/pages/ContactForm.js
import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { supabase } from '../supabase'; // Import Supabase client
import thankYouImage from '../assets/images/Thank_You_Image.png'; // Import the image

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  width: 100%;
`;

const Input = styled.input`
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  grid-column: span 2;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
    grid-column: span 2;
  }
`;

const SmallInput = styled(Input)`
  grid-column: span 1;

  @media (max-width: 768px) {
    grid-column: span 2;
  }
`;

const Textarea = styled.textarea`
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  grid-column: span 2;

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
    grid-column: span 2;
  }
`;

const Button = styled.button`
  padding: 12px;
  background-color: #003366;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  grid-column: span 2;
  justify-self: end;

  &:hover {
    background-color: #002244;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 10px;
    grid-column: span 2;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ThankYouModal = styled.div`
  display: ${props => props.show ? 'flex' : 'none'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalImage = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contact: '',
    destination: '',
    qualification: '',
    intake: '',
    message: ''
  });

  const [showThankYou, setShowThankYou] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const modalRef = useRef();

  useEffect(() => {
    const isValid = Object.values(formData).every(value => value.trim() !== '');
    setIsFormValid(isValid);
  }, [formData]);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setFormData(prevData => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isFormValid) {
      try {
        const { data, error } = await supabase
          .from('contact_forms') // Ensure table name is correct
          .insert([{
            ...formData,
            submitted_at: new Date().toISOString() // Include the current date and time
          }]);
  
        if (error) {
          console.error('Supabase error:', error.message); // Log error message
          alert('Failed to send message');
          return;
        }
  
        console.log('Data:', data); // Log the response data
        setShowThankYou(true);
  
        // Clear form data after submission
        setFormData({
          name: '',
          email: '',
          contact: '',
          destination: '',
          qualification: '',
          intake: '',
          message: ''
        });
  
        // Hide modal after 5 seconds
        setTimeout(() => {
          setShowThankYou(false);
        }, 5000);
      } catch (error) {
        console.error('Unexpected error:', error.message); // Log error message
        alert('Failed to send message');
      }
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setShowThankYou(false);
    }
  };

  useEffect(() => {
    if (showThankYou) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showThankYou]);

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Input id="name" type="text" placeholder="Akanksha Sachin Pandey" value={formData.name} onChange={handleChange} />
        <SmallInput id="email" type="email" placeholder="Email Address" value={formData.email} onChange={handleChange} />
        <SmallInput id="contact" type="text" placeholder="Contact Number" value={formData.contact} onChange={handleChange} />
        <SmallInput id="destination" type="text" placeholder="Preferred Destination" value={formData.destination} onChange={handleChange} />
        <SmallInput id="qualification" type="text" placeholder="Highest Qualification" value={formData.qualification} onChange={handleChange} />
        <SmallInput id="intake" type="text" placeholder="Planned Intake" value={formData.intake} onChange={handleChange} />
        <Textarea id="message" placeholder="Your Message Here" rows="4" value={formData.message} onChange={handleChange}></Textarea>
        <Button type="submit" disabled={!isFormValid}>Submit</Button>
      </Form>
      <ThankYouModal show={showThankYou}>
        <ModalContent ref={modalRef}>
          <ModalImage src={thankYouImage} alt="Thank You" />
        </ModalContent>
      </ThankYouModal>
    </>
  );
};

export default ContactForm;
