import React from 'react';
import styled from 'styled-components';
import WorldMap from '../assets/images/Map_World_Wide.png';
import MarkLocation from '../assets/images/Mark_Location.png';

const MapContainer = styled.div`
  text-align: center;
  padding: 10px;
  background-color: #EFEFEF;
  width: 98.2%;
  overflow: hidden;

  @media (max-width: 768px) {
    padding: 5px;
  }
`;

const StyledH2 = styled.h2`
  color: #02155A;
  font-size: 2em;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    font-size: 1.5em;
    padding: 10px;
  }
`;

const Map = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 1000px;
  height: auto;
`;

const MapImage = styled.img`
  width: 100%;
  height: auto;
`;

const Locations = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Location = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;

  img {
    width: 30px;
    height: 40px;

    @media (max-width: 768px) {
      width: 20px;
      height: 30px;
    }
  }

  span {
    display: block;
    font-size: 14px;
    color: #02155A;
    padding: 5px;

    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

const MapComponent = () => {
  return (
    <MapContainer>
      <StyledH2>Our Presence Worldwide</StyledH2>
      <Map>
        <MapImage src={WorldMap} alt="World Map" />
        <Locations>
          <Location style={{ top: '24%', left: '15.2%' }}>
            <span>USA</span>
            <img src={MarkLocation} alt="USA" />
          </Location>
          <Location style={{ top: '42%', left: '43%' }}>
            <span>Africa</span>
            <img src={MarkLocation} alt="Africa" />
          </Location>
          <Location style={{ top: '23%', left: '51.6%' }}>
            <span>Europe</span>
            <img src={MarkLocation} alt="Europe" />
          </Location>
          <Location style={{ top: '25%', left: '71.3%' }}>
            <span>Asia</span>
            <img src={MarkLocation} alt="Asia" />
          </Location>
          <Location style={{ top: '69%', left: '91.5%' }}>
            <span>Oceania</span>
            <img src={MarkLocation} alt="Oceania" />
          </Location>
        </Locations>
      </Map>
    </MapContainer>
  );
};

export default MapComponent;
