import React from 'react';
import styled from 'styled-components';
import TeamOfExpertsIcon from '../assets/images/Teams_Experts.png';
import BestSolution from '../assets/images/Best_Solution.png';
import Consultants from '../assets/images/Consultants.png';
import FinanceGuidance from '../assets/images/Finance_Guidance.png';

const FeaturesContainer = styled.div`
  display: flex;
  flex-wrap: nowrap; /* Keep items in a single row */
  padding: 20px;
  background-color: #FFFCF6;
  justify-content: space-between; /* Distribute items evenly */

  @media (max-width: 1024px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    padding: 10px;
    justify-content: space-around; /* Space items evenly with some space on the sides */
  }

  @media (max-width: 480px) {
    padding: 5px;
    justify-content: space-between; /* Space items evenly with no scroll */
  }
`;

const FeatureItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1.5px solid #02155A;
  border-radius: 8px;
  height: 120px; /* Reduced height */
  text-align: center;
  box-sizing: border-box;
  margin: 0 5px; /* Adjusted margin for better spacing */

  /* Responsive width */
  width: calc(100% / 4 - 20px); /* Default: 4 items per row with margin adjustment */

  @media (max-width: 1024px) {
    width: calc(100% / 4 - 20px); /* Same width to fit all items in a row */
  }

  @media (max-width: 768px) {
    width: calc(100% / 4 - 15px); /* Adjusted width for medium screens */
  }

  @media (max-width: 480px) {
    width: calc(100% / 4 - 10px); /* Adjusted width for small screens to fit all items */
  }
`;

const FeatureIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
  }

  @media (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
`;

const FeatureText = styled.p`
  color: #02155A;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
  }

  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const Line = styled.span`
  display: block;
`;

const Features = () => {
  return (
    <FeaturesContainer>
      <FeatureItem>
        <FeatureIcon src={TeamOfExpertsIcon} alt="Team of Experts" />
        <FeatureText><Line>Team of</Line>Experts</FeatureText>
      </FeatureItem>
      <FeatureItem>
        <FeatureIcon src={Consultants} alt="Friendly Consultants" />
        <FeatureText><Line>Friendly</Line>Consultants</FeatureText>
      </FeatureItem>
      <FeatureItem>
        <FeatureIcon src={BestSolution} alt="Best Solutions" />
        <FeatureText><Line>Best</Line>Solutions</FeatureText>
      </FeatureItem>
      <FeatureItem>
        <FeatureIcon src={FinanceGuidance} alt="Finance Guidance" />
        <FeatureText><Line>Finance</Line>Guidance</FeatureText>
      </FeatureItem>
    </FeaturesContainer>
  );
};

export default Features;
